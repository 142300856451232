<template>
  <b-col md="12">
    <app-collapse type="margin">
      <app-collapse-item title="">
        <template #header>
          <b-form-checkbox
            v-model="dataModule.Active"
            class="custom-control-primary"
          >
            {{ dataModule.Name }}
          </b-form-checkbox>
        </template>
        <b-form-group
          v-if="Object.keys(dataModule.Actions).length > 0"
          label="Actions :"
          label-for="Actions"
        >
          <div style="display: flex;">
            <div
              v-for="(action, indexi) in dataModule.Actions"
              :key="indexi"
              style="margin-right: 25px;"
            >
              <b-form-checkbox
                v-model="action.Active"
                class="custom-control-primary"
              >
                {{ action.Name }}
              </b-form-checkbox>
            </div>
          </div>
        </b-form-group>

        <b-form-group
          v-if="Object.keys(dataModule.children).length > 0"
          label="Childrens :"
          label-for="Actions"
        >
          <div
            class="items-children"
          >
            <b-row>
              <recursive-module
                v-for="(children, index) in dataModule.children"
                :key="index"
                :data-module="children"
              />
            </b-row>
          </div>
        </b-form-group>
      </app-collapse-item>
    </app-collapse>
  </b-col>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'RecursiveModule',
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    dataModule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>
