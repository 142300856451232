<template>
  <b-card>
    <div style="margin-bottom: 20px;">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mr-2">Name:</label>
          <b-form-input
            v-model="NameProfile"
            class="d-inline-block mr-1"
            placeholder="Introduce a Name..."
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="dataInfo ? EditAccessLevel() : CreateAccessLevel()"
            >
              {{ dataInfo ? 'Edit Access Level' : 'Create Access Level' }}
            </b-button>

          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <recursive-module
          v-for="(item, index) in AccessLevelModel"
          :key="index"
          :data-module="item"
        />
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import permissionServices from '@core/services/permissions/permissionService'
import Swal from 'sweetalert2'
import recursiveModule from './RecursiveModule.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    recursiveModule,
  },
  data() {
    return {
      AccessLevelModel: [],
      NameProfile: '',
      dataInfo: false,
      IdAccesLevel: 0,
    }
  },
  created() {
    this.getAccessLevel()
  },
  methods: {
    getAccessLevel() {
      const id = this.$store.getters['access-level/getIdAccessLevel']
      this.IdAccesLevel = id
      if (id !== 0) this.dataInfo = true
      permissionServices.getModulesPermission({
        id,
      }).then(response => {
        const { permissions, Name } = response.data.data
        this.AccessLevelModel = permissions
        this.NameProfile = Name
      })
    },
    EditAccessLevel() {
      let Text = ''
      if (this.NameProfile.length === 0) {
        Text += '-Name Profile missing<br>'
      } else {
        Text += ''
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Profile Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
      } else {
        Swal.fire({
          title: 'Attention',
          text: '¿Do you want to edit this access level?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Edit',
        }).then(result => {
          if (result.isConfirmed) {
            permissionServices.updatePermissionProfile({
              id: this.IdAccesLevel,
              name: this.NameProfile,
              permissions: this.AccessLevelModel,
            }).then(() => {
              this.$router.push({ name: 'access-level' })
              Swal.fire(
                'Success',
                'Acces Level Edited!',
                'success',
              )
            }).catch(error => {
              this.toastErrorHandler(error)
            })
          }
        })
      }
    },
    CreateAccessLevel() {
      let Text = ''
      if (this.NameProfile.length === 0) {
        Text += '-Name Profile missing<br>'
      } else {
        Text += ''
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Profile Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
      } else {
        Swal.fire({
          title: 'Attention',
          text: '¿Do you want to create this access level?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Create',
        }).then(result => {
          if (result.isConfirmed) {
            permissionServices.createPermissionProfile({
              name: this.NameProfile,
              permissions: this.AccessLevelModel,
            }).then(() => {
              this.$router.push({ name: 'access-level' })
              Swal.fire(
                'Success',
                'Acces Level Created!',
                'success',
              )
            }).catch(error => {
              this.toastErrorHandler(error)
            })
          }
        })
      }
    },
  },
}
</script>

<style>
</style>
